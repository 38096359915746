import {
  Image,
  Text,
  Flex,
  Stack,
  Grid,
  GridItem,
  Spacer,
} from "@chakra-ui/react";

import Overview from "./overview";
import DescriptionGallery from "./description-gallery";

import TeacherImg1 from "../images/teacher-1.webp";
import TeacherImg2 from "../images/teacher-2.webp";
import GridImg1 from "../images/grid-1.webp";
import GridImg2 from "../images/grid-2.jpeg";
import GridImg3 from "../images/grid-3.webp";
import TeamImg from "../images/team1.webp";

import ParallexBox from "./parallex-box";

export default function Testimonies() {
  const textProps = {
    fontSize: ["3xl", "xl", "3xl"],
    fontWeight: "semibold",
    color: "brand.100",
  };
  return (
    <Flex direction="column">
      <Stack
        direction={["column", "row", "row"]}
        align="center"
        maxW={["100%", "100%", "80%"]}
        pl={[0, 0, "10%"]}
      >
        <Grid
          rounded="full"
          bg="brand.300"
          templateRows="repeat(7, 1fr)"
          templateColumns="repeat(7, 1fr)"
          minW={[200, 400, 600]}
          margin={4}
        >
          <GridItem rowSpan={6} colSpan={6}>
            <ParallexBox start={-10} end={30} pb={8} pr={4}>
              <Image src={TeacherImg1} />
            </ParallexBox>
          </GridItem>
          <GridItem rowSpan={7} />
        </Grid>
        <Flex align="center" justify="center">
          <Text py={[8, 12, 12]} px={[8, 8, 4]} {...textProps} as="i">
            "He has improved his handwriting, reading, and eyesight in
            particular, and all of his academics have increased in general. He
            now has more confidence and visual stamina... More focused; More
            coordinated."
            <br /> — Mrs Tan, Parent
          </Text>
        </Flex>
      </Stack>
      <Spacer />
      <Stack direction={["column", "row", "row"]} align="center" py={12}>
        <Text {...textProps} as="i" px={[8, 8, "10%"]}>
          Previously, I found it challenging to organise my thoughts for
          narrative writing. After some lessons, I not only got to sharpen my
          skills, but also found my passion for story writing. The experience I
          had was great and beneficial to my media career. I would love to
          strive for the best with Witzkit.
          <br />— Mei Yue
        </Text>
        <Grid
          rounded="full"
          bg="brand.300"
          templateColumns="repeat(9, 1fr)"
          templateRows="repeat(9, 1fr)"
          minW={[200, 400, 600]}
          margin={4}
        >
          <GridItem colSpan={1} rowSpan={7} />
          <GridItem colSpan={8} rowSpan={8} padding={4}>
            <ParallexBox start={30} end={-10} pb={8} pr={4}>
              <Image src={TeacherImg2} rounded="full" />
            </ParallexBox>
          </GridItem>
        </Grid>
      </Stack>
      <DescriptionGallery />
      <Stack
        direction={["column", "row", "row"]}
        align="center"
        py={12}
        spacing={[8, 0, 0]}
        maxW={["100%", "100%", "90%"]}
        pl={[0, 0, "5%"]}
      >
        <Grid
          templateColumns={[
            "repeat(1, 1fr)",
            "repeat(1, 1fr)",
            "repeat(2, 1fr)",
          ]}
          templateRows={["repeat(4, 1fr)", "repeat(4, 1fr)", "repeat(2, 1fr)"]}
          minW={[200, 400, 600]}
        >
          <GridItem colSpan={1} rowSpan={1} margin={1}>
            <Image
              src={GridImg1}
              height={["100%", 300, 300]}
              width={["100%", 300, 300]}
              objectPosition={"bottom center"}
              objectFit="cover"
            />
          </GridItem>
          <GridItem colSpan={1} rowSpan={1} margin={1}>
            <Image
              src={TeamImg}
              height={["100%", 300, 300]}
              width={["100%", 300, 300]}
              objectPosition={"top center"}
              objectFit="cover"
            />
          </GridItem>
          <GridItem colSpan={1} rowSpan={1} margin={1}>
            <Image
              src={GridImg2}
              height={["100%", 300, 300]}
              width={["100%", 300, 300]}
              objectPosition={"top center"}
              objectFit="cover"
            />
          </GridItem>
          <GridItem colSpan={1} rowSpan={1} margin={1}>
            <Image
              src={GridImg3}
              height={["100%", 300, 300]}
              width={["100%", 300, 300]}
              objectPosition={"top center"}
              objectFit="cover"
            />
          </GridItem>
        </Grid>
        <Stack align="center" justify="center" spacing={12}>
          <Text px={[8, 8, 8]} {...textProps} as="i">
            I am confident about my abilities now; I know what I am good at and
            what training I needed to perform better in school. I don’t have a
            headache any more when preparing for or taking tests. <br />— Lee
            Song Xuan
          </Text>
          <Text px={[8, 8, 4]} {...textProps} as="i">
            "I'm so pleased with the progress of our younger daughter. Not only
            was there a dramatic improvement in her grades but also in the way
            she relates to others. Her self confidence has really improved. Life
            is easier and less stressful now that she spells and writes better."
            <br />— Mrs Ng, Parent
          </Text>
        </Stack>
      </Stack>

      <Spacer />
      <Overview />
    </Flex>
  );
}
