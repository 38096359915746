import { AspectRatio } from "@chakra-ui/react";

export default function Map() {
  return (
    <AspectRatio ratio={16 / 9}>
      <iframe
        title="map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7774184596924!2d103.85942471620886!3d1.3088063990454244!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da19c9f6f9c24b%3A0x18dd5d7c1b07d49a!2sWitzkit%20Learning%20Development%20Centre!5e0!3m2!1sen!2ssg!4v1671111359333!5m2!1sen!2ssg"
        // width="600"
        // height="450"
        style={{ border: 0 }}
        allowFullScreen={true}
        loading="lazy"
      />
    </AspectRatio>
  );
}
