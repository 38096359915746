import { Heading, Text } from "@chakra-ui/react";

export default function Privacy() {
  return (
    <div>
      <Heading>Data Protection Notice</Heading>
      <Text>
        This privacy policy explains how Witzkit pte. ltd. (referred to as "we",
        "us", or "our" in this policy) collects, uses, and shares information
        about you when you use our website witzkit.com (the "Site"). By using
        the Site, you agree to the collection, use, and sharing of your
        information as described in this privacy policy.
        <br />
        We may change our privacy policy from time to time. We encourage you to
        review this privacy policy whenever you access the Site to stay informed
        about our information practices and the choices available to you.
      </Text>
      <Heading as="h2" size="md">
        Information We Collect
      </Heading>
      <Text>
        As used in this Notice, “you” means an individual who (a) has contacted
        us through any means to find out more about any services we provide, or
        (b) may, or has, entered into a contract with us for the supply of any
        services by us; and “personal data” means data, whether true or not,
        about an individual who can be identified: (a) from that data; or (b)
        from that data and other information to which we have or are likely to
        have access. We collect information about you when you provide it to us
        directly, such as when you contact us, use our contact form, or engage
        our services. The types of information we may collect include your name,
        email address, and telephone number, gender, and employment information.
        <br />
        We also collect information about you automatically when you use the
        Site, such as your IP address, device type, and browser type. We use
        this information to analyze usage trends and improve the Site.
      </Text>
      <Heading as="h2" size="md">
        Use of Your Information
      </Heading>
      <Text>
        We use the information we collect about you to provide, maintain, and
        improve the Site and to communicate with you. For example, we may use
        your information to: Respond to your requests or inquiries, send you
        emails or other communications, or operate and improve the Site. We may
        also use your information for research and analytics purposes and to
        personalize your experience on the Site.
      </Text>
      <Heading as="h2" size="md">
        Sharing of Your Information
      </Heading>
      <Text>
        We may share your information with third parties in the following
        circumstances: With service providers who perform services on our
        behalf, such as hosting, analysis, and marketing services; In response
        to legal process, such as a court order or subpoena; To protect the
        rights, property, or safety of Witzkit pte. ltd., our users, or the
        public
      </Text>
      <Heading as="h2" size="md">
        Data Rention and Security
      </Heading>
      <Text>
        We take reasonable steps to protect the information we collect from
        loss, misuse, and unauthorized access, disclosure, alteration, and
        destruction. However, no internet transmission is ever completely secure
        or error-free, so you should take special care in deciding what
        information you send to us. We will retain your information for as long
        as needed to provide you with the services you have requested or as
        required by law.
      </Text>
      <Heading as="h2" size="md">
        Your Choices and Access to Your Information
      </Heading>
      <Text>
        You have the right to request access to the information we collect about
        you and to request that we correct or delete it. You can also opt out of
        marketing communications at any time by following the unsubscribe
        instructions provided in those communications. To exercise these rights,
        or if you have any questions about our privacy policy, you can contact
        us at witzkitfli2@gmail.com. We will respond to your request in a
        reasonable time and in accordance with applicable law. Please note that
        we may need to verify your identity before granting access to or making
        any changes to your information.
      </Text>
      <Heading as="h2" size="md">
        Children’s Privacy
      </Heading>
      <Text>
        Although we enroll children in our programs, our site is not directed to
        children.
      </Text>
    </div>
  );
}
