import { Stack, Text, Box, Icon, Show } from "@chakra-ui/react";
import { MdLocationOn } from "react-icons/md";
import Map from "./map";
import ContactCard from "./contact-card";
import MobileCopyText from "./mobile-copy-text";

export default function LocateUs() {
  // const toast = useToast();
  // probably want to style this as a card too to be consistent with other
  // components

  // copy address when some link overlay component is clicked
  // show a toast to indicate that the address has been copied

  return (
    <Box bg="brand.0" px={6}>
      <ContactCard
        icon={<Icon as={MdLocationOn} boxSize={10} color="brand.100" />}
        width="100%"
        footer="We are open from 10am to 6pm SGT on weekdays. Till 4pm on Saturdays."
        detail={"#07-165 808 French Road, Singapore 200808"}
      >
        <Stack bg="brand.0" spacing={4}>
          <Map />
          <Text color="brand.100" fontSize="lg">
            Address: 808 French Rd (Kitchener Complex) #07-165
            <br />
            Singapore 200808
          </Text>
          <MobileCopyText>Click to copy address</MobileCopyText>
        </Stack>
      </ContactCard>
    </Box>
  );
}
