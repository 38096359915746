import { Button } from "@chakra-ui/react";
import { HashLink } from "react-router-hash-link";

export default function CtaButton() {
  return (
    <HashLink to="/#contact">
      <Button
        size="lg"
        borderRadius="full"
        px={12}
        py={6}
        fontSize="lg"
        fontWeight="bold"
        bg="brand.300"
        color="brand.0"
      >
        Get in touch
      </Button>
    </HashLink>
  );
}
