import { Box, Heading, Stack, Text, Show } from "@chakra-ui/react";
import { EmailIcon, PhoneIcon, CopyIcon } from "@chakra-ui/icons";
import ContactCard from "./contact-card";
import MobileCopyText from "./mobile-copy-text";

export default function ContactUs() {
  // move this to a separate component ?
  // TODO: clicking the contact card should copy the details to clipboard
  return (
    <Box bg="brand.0">
      <Heading fontSize="4xl" color="brand.100" py={4}>
        Get in touch to join our next intake
      </Heading>
      <Stack direction={["column", "column", "row"]} spacing={4} px={6} py={4}>
        <ContactCard
          icon={<PhoneIcon boxSize={8} color="brand.100" />}
          width={["100%", "100%", "50%"]}
          footer="We take calls from 10am to 6pm SGT on weekdays"
          detail={"+65 6299 5321"}
        >
          <Text color="brand.100" fontSize="lg">
            Phone: +65 6299 5321
          </Text>
          <MobileCopyText>Click to copy phone number</MobileCopyText>
        </ContactCard>
        <ContactCard
          icon={<EmailIcon boxSize={8} color="brand.100" />}
          width={["100%", "100%", "50%"]}
          footer="Give us 3 working days to respond to emails"
          detail={"hello@witzkit.com"}
        >
          <Text color="brand.100" fontSize="lg">
            Email: hello@witzkit.com
          </Text>
          <MobileCopyText>Click to copy email address</MobileCopyText>
        </ContactCard>
      </Stack>
    </Box>
  );
}
