import { Text, Show } from "@chakra-ui/react";
import { CopyIcon } from "@chakra-ui/icons";

export default function MobileCopyText({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Show below="sm">
      <Text color="gray.300" fontSize="sm">
        <CopyIcon />
        {children}
      </Text>
    </Show>
  );
}
