import { Stack, Heading, Text, Box } from "@chakra-ui/react";
import TypeWriter from "typewriter-effect";

interface OverviewProps {
  color: string;
}

export default function Overview({ color }: OverviewProps) {
  const pauseTime = 1000;
  return (
    <Stack align="center" spacing={12} py={12}>
      <Heading
        fontSize="4xl"
        color={color === "light" ? "brand.100" : "brand.0"}
      >
        Become a better{" "}
        <TypeWriter
          options={{
            autoStart: true,
            loop: true,
          }}
          onInit={(writer) => {
            writer
              .typeString("learner")
              .pauseFor(pauseTime)
              .deleteAll()
              .typeString("reader")
              .pauseFor(pauseTime)
              .deleteAll()
              .typeString("writer")
              .pauseFor(pauseTime)
              .deleteAll()
              .typeString("problem solver")
              .pauseFor(pauseTime)
              .deleteAll()
              .typeString("student")
              .pauseFor(pauseTime)
              .deleteAll()
              .typeString("listener")
              .pauseFor(pauseTime)
              .deleteAll()
              .start();
          }}
        />
      </Heading>
      <Box maxW={["100%", "100%", "50%"]}>
        <Text
          fontSize="3xl"
          color={color === "light" ? "brand.100" : "brand.200"}
        >
          Achieve your academic, professional, or person goals with our
          tailored, Singapore-based development program.
        </Text>
      </Box>
    </Stack>
  );
}

// set default props
Overview.defaultProps = {
  color: "light",
};
