import {
  Box,
  Flex,
  Heading,
  Link,
  Stack,
  Text,
  Image,
  Spacer,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import LocateUs from "../components/locate-us";
import ContactForm from "../components/contact-form";
import Testimonies from "../components/testimonies";
import FaqSection from "../components/faq-section";
import ContactUs from "../components/contact-us";

import ImageGallery from "../components/image-gallery";
import Misc1 from "../images/misc-1.webp";
import Misc2 from "../images/misc-2.jpeg";
import Misc3 from "../images/misc-3.jpeg";
import Misc4 from "../images/misc-4.jpeg";
import Misc5 from "../images/misc-5.jpeg";
import Misc6 from "../images/misc-6.jpeg";
import Misc7 from "../images/misc-7.jpeg";

import CtaButton from "../components/cta-button";

import logo from "../images/witzkit-logo-white-nobg.png";

const faqItems = [
  {
    title: "What kind of improvements can I expect?",
    content: [
      "A person that has been through the program can expect to see a holistic improvement in both their work or school performance and their day-to-day activities.",
      "Witzkit uses an integrated approach to improve learning ability. We work to target the root causes of learning problems and also seek to develop learning skills holistically. The more developed the person is, the more fluent they become in both physical and mental activities. ",
    ],
  },
  {
    title: "Can Witzkit help with Mathematics?",
    content: [
      "Poor arithmetic skills may not be the only reason that someone struggles with mathematics.",
      "Being able to solve a mathematics question often also involves having to understand the word description of the problem. Being able to accurately compute a solution can also be hampered by carelessness or confusion. Some students may also struggle with identifying patterns in similar mathematics problems— these students may struggle to solve a problem despite having seen a similar example recently.",
      "Struggling with mathematics is a common problem faced by many students that we enroll, and we have an extensive track record with being able to help students improve at mathematics. Through the Witzkit program, we will help you to isolate, identify, and improve on the skills necessary to perform well in mathematics.",
    ],
  },
  {
    title: "Can Witzkit help with Reading Comprehension?",
    content: [
      "Reading comprehension comprises of other more basic skills such as vocabulary, the ability to recall details and connect concepts, and even the ability to focus,",
      "For example, poor vocabulary and inference skills can make it difficult to interpret the meaning of the text. On the other hand, difficulty paying attention can result in carelessness while dealing with long words or texts. At its extreme, the inability the focus may result in portions of the text being glossed over. Comprehending a text also involves putting together a coherent narrative and understanding its themes. Not being able to recall or connect previous ideas while reading can make this difficult.",
      "The Witzkit program has an extensive track record of helping students improve at reading comprehension. Our unique approach allows us to diagnose the root causes of each student’s struggles and help them to improve upon the necessary skills to succeed in reading comprehension.",
    ],
  },
  {
    title: "Can Witzkit help with Behavioral Issues?",
    content: [
      "Behavioural issues may not always be an issue with the individual’s values, upbringing or environment. Some individuals with behavioural issues may appear to be unresponsive to intervention and there seems to be no obvious social explanations for their behaviour. In such instances, underdeveloped cognitive or physical abilities could be a cause of their inappropriate behaviour.",
      "These types of inappropriate behaviours may include being unable to follow instructions, not being able to remain still, and being too intrusive. Other types of behaviours display a lack of body awareness such as clumsiness, poor handwriting, and carelessness while copying. Such behaviours can be disruptive to an individual’s learning experience and also come with cognitive and physical learning disadvantages that should be addressed to allow better learning.",
      "The Witzkit program has a successful track record of dealing with the root causes of such behaviour through our unique approach and understanding of the connection between physical ability and cognitive function.",
    ],
  },
  {
    title: "Where does the program take place?",
    content: [
      "Our program takes place in-person at our learning center, located at 808 French Rd, Singapore 200808.",
      "we are open from 10am – 6pm on Mondays to Fridays, and from 9am to 5pm on Saturdays. ",
    ],
  },
  {
    title: "Who should enroll?",
    content: [
      "Learning problems affect people of all ages and can take different forms, such as:",
      <ul>
        <li>Difficulty focusing</li>
        <li>Poor memory</li>
        <li>Behavioural issues</li>
        <li>Difficulty with social interactions</li>
        <li>Clumsiness</li>
        <li>Poor reading comprehension</li>
        <li>Poor posture</li>
        <li>Academic/performance issues</li>
      </ul>,
      "As such, our program caters to students of every kind - from pre-schoolers to working adults, and from individuals looking to better manage their learning needs to the gifted looking to further leverage their strengths.",
    ],
  },
  {
    title: "What is the enrollment process?",
    content: [
      "The first step that we take in our program is to administer a comprehensive set of two assessments— an academic test and a physical test— to obtain a full picture of the individual’s learning abilities. After this assessment, a personalized training program is designed for the individual based on their needs.",
      "Please contact us to make an appointment for these assessments. You may call our office at +65 6299 5321 or WhatsApp us at +65 9645 4612 ",
    ],
  },
  {
    title: "What is the pricing scheme?",
    content: [
      "There will be a one-off assessment cost to provide both you and us with a breakdown of the individual’s learning profile and help us build a tailored program for the individual. The fees of the program itself will depend on the duration and services being offered. Due to the bespoke nature of our program, it is best to speak to us directly about pricing matters.",
      "Please call our office number at +65 6299 5321 or WhatsApp +65 9645 4612 for more information on the pricing of our program.",
    ],
  },
];

export default function Home() {
  // website landing page with a banner, description, and then contact info
  // design should be responsive and mobile friendly
  // use chakra-ui for styling
  // use react-router-dom for routing
  // separate the page into sections, each with full width backgrounds
  return (
    <Stack>
      <Flex
        bg="brand.100"
        minW="100vw"
        align="center"
        justify="center"
        direction="column"
      >
        <Stack maxW="2xl" spacing={8} py={[12, 12, 32]} align="center">
          <Image src={logo} maxW="240px" alt="Witzkit Logo" py="8" />
          <Heading fontSize="4xl" color="brand.0">
            We empower learners
          </Heading>
          <Text fontSize="xl" color="brand.200" px={8}>
            By equipping them with the right skills to achieve lasting success
            through targeted, holistic support.
          </Text>
          <Text as="i" fontSize="xl" color="brand.0">
            Consider the Witzkit development program
          </Text>
        </Stack>
        <Box pb={12}>
          <CtaButton />
        </Box>
      </Flex>
      <Testimonies />
      <Flex
        bg="brand.0"
        minW="100vw"
        justify="center"
        align="center"
        direction="column"
      >
        <Stack maxW="2xl" py={12} spacing={4} id="contact">
          <ContactUs />
          <LocateUs />
          <Spacer />
          <Heading fontSize="2xl">
            Or leave your email and we'll get back to you
          </Heading>
          <Spacer />
          <ContactForm />
        </Stack>
      </Flex>
      <ImageGallery
        imageSources={[Misc1, Misc3, Misc5, Misc2, Misc7, Misc4, Misc6]}
      />
      <Flex
        bg="brand.0"
        justify="center"
        align="center"
        direction="column"
        minW="100vw"
      >
        <Heading py={12} fontSize="3xl">
          FAQs
        </Heading>
        <Box boxSize={"100%"}>
          <FaqSection items={faqItems} />
        </Box>
        <Box padding={12}>
          <CtaButton />
        </Box>
      </Flex>
      <Box bg="brand.100" minW="100vw">
        <Stack
          direction={["column", "column", "row"]}
          py={12}
          px={12}
          spacing={8}
        >
          <Link color="brand.200">
            <HashLink to="#contact">Contact</HashLink>
          </Link>
          <Link color="brand.200">
            <RouterLink to="/privacy">Privacy</RouterLink>
          </Link>
          <Text color="brand.0">🇸🇬 Witzkit Pte. Ltd. </Text>
        </Stack>
      </Box>
    </Stack>
  );
}
