import { useToast } from "@chakra-ui/react";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Text,
  Tooltip,
} from "@chakra-ui/react";

interface ContactCardProps {
  icon: React.ReactNode;
  children: React.ReactNode;
  footer?: string;
  width?: string | string[];
  detail: string;
}

function copyToClipboard(str: string) {
  navigator.clipboard.writeText(str);
}

export default function ContactCard({
  icon,
  children,
  width,
  footer,
  detail,
}: ContactCardProps) {
  const toast = useToast();
  return (
    <Tooltip label={"Copy " + detail + " to clipboard"} hasArrow bg="brand.100">
      <Card
        width={width}
        borderColor="brand.200"
        borderWidth="2px"
        borderRadius="24"
        boxShadow="lg"
        onClick={() => {
          copyToClipboard(detail);
          toast({
            title: detail + " copied to clipboard",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }}
      >
        <CardHeader>{icon}</CardHeader>
        <CardBody>{children}</CardBody>
        <CardFooter alignContent="center">
          <Text textAlign={["center", "center", "center"]}>
            {footer || "We will get back to you within 3 working days"}
          </Text>
        </CardFooter>
      </Card>
    </Tooltip>
  );
}
