import { Card, CardBody, CardHeader, Text } from "@chakra-ui/react";

interface CardProps {
  icon: React.ReactNode;
  title: string;
  children: React.ReactNode;
  color: string;
  width?: string | string[];
}

export default function DescriptionCard({
  icon,
  title,
  children,
  color,
  width,
}: CardProps) {
  return (
    <Card
      width={width}
      borderColor="brand.0"
      borderWidth="2px"
      borderRadius="24"
    >
      <CardHeader>
        {icon}{" "}
        <Text fontSize="xl" fontWeight="bold" color={color}>
          {title}
        </Text>
      </CardHeader>
      <CardBody color={color}>
        <Text color={color} fontSize="lg">
          {children}
        </Text>
      </CardBody>
    </Card>
  );
}
