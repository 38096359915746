import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./home";
import Privacy from "./privacy";

export default function PageRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
    </Router>
  );
}
