import { Accordion, Text } from "@chakra-ui/react";
import TextAccordion from "./text-accordion";

interface FaqItem {
  title: string;
  content: (string | JSX.Element)[];
}

interface FaqSectionProps {
  items: FaqItem[];
}

export default function FaqSection({ items }: FaqSectionProps) {
  return (
    <Accordion px={["10%", "10%", "20%"]}>
      {items.map((item) => (
        <TextAccordion title={item.title}>
          {item.content.map((content) => (
            <Text textAlign="left" py={2}>
              {content}
            </Text>
          ))}
        </TextAccordion>
      ))}
    </Accordion>
  );
}
