import { inputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  field: {
    fontFamily: "mono", // change the font family
    color: "teal.600", // change the input text color
    // borderTopRightRadius: "full",
    // borderBottomRightRadius: "full",
    bg: "gray.50",
    // _focusVisible: {
    //   bg: "white",
    //   borderColor: "white",
    // },
  },
  //   addon: {
  //     border: "0px solid",
  //     borderColor: "transparent",
  //     borderTopLeftRadius: "full",
  //     borderBottomLeftRadius: "full",
  //     bg: "brand.100",
  //     color: "white",
  //   },
});

const variantCustom = definePartsStyle((props) => {
  const { colorScheme: c } = props;
  return {
    field: {
      border: "1px solid",
      borderColor: "brand.200",
      bg: "gray.50",
      borderTopRightRadius: "full",
      borderBottomRightRadius: "full",
      _dark: {
        bg: "whiteAlpha.50",
      },

      _hover: {
        bg: "gray.100",
        _dark: {
          bg: "whiteAlpha.100",
        },
      },
      _readOnly: {
        boxShadow: "none !important",
        userSelect: "all",
      },
      _focusVisible: {
        bg: "gray.50",
        _dark: {
          bg: "whiteAlpha.100",
        },
      },
    },
    addon: {
      border: "0px solid",
      borderColor: "transparent",
      borderTopLeftRadius: "full",
      borderBottomLeftRadius: "full",
      bg: `brand.100`,
      color: "white",
      _dark: {
        bg: `${c}.300`,
        color: `${c}.900`,
      },
    },
    element: {
      bg: "white",
      rounded: "full",
      border: "1px solid",
      borderColor: "brand.200",
      _dark: {
        bg: "whiteAlpha.50",
        borderColor: "whiteAlpha.100",
      },
    },
  };
});

export const inputTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { custom: variantCustom },
});
