import { extendTheme } from "@chakra-ui/react";
import { buttonTheme } from "./button";
import { inputTheme } from "./input";

export const theme = extendTheme({
  colors: {
    brand: {
      0: "#FFFFFF",
      100: "#001730",
      200: "#4AD7D1",
      300: "#FE5A5A",
    },
  },
  components: {
    Button: buttonTheme,
    Input: inputTheme,
  },
});
