import { useState, useRef } from "react";
import {
  motion,
  useScroll,
  useSpring,
  useTransform,
  MotionValue,
} from "framer-motion";
import { Box, BoxProps } from "@chakra-ui/react";

interface ParallexImageProps extends BoxProps {
  children?: React.ReactNode;
  start: number;
  end: number;
}

function useParallax(
  value: MotionValue<number>,
  startDistance: number,
  endDistance: number
) {
  return useSpring(useTransform(value, [0, 1], [startDistance, endDistance]), {
    stiffness: 100,
    damping: 30,
  });
}

export default function ParallexBox({
  start,
  end,
  children,
  ...props
}: ParallexImageProps) {
  const { scrollYProgress } = useScroll();
  const y = useParallax(scrollYProgress, start, end);

  return (
    <Box {...props}>
      <motion.div style={{ x: y }}>{children}</motion.div>
    </Box>
  );
}
