import React from "react";
import "./App.css";
import PageRouter from "./pages/page-router";

import { theme } from "./theme/baseTheme";
import { ChakraProvider } from "@chakra-ui/react";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <div className="App">
        <PageRouter />
      </div>
    </ChakraProvider>
  );
}

export default App;
