import { Stack, Icon } from "@chakra-ui/react";
import DescriptionCard from "./description-card";

import {
  BsFillSuitHeartFill,
  BsFillPersonLinesFill,
  BsFillStarFill,
} from "react-icons/bs";

export default function DescriptionGallery() {
  return (
    <Stack
      bg="brand.100"
      direction={["column", "column", "row"]}
      justifyItems="center"
      px={8}
      py={24}
      spacing={8}
    >
      <DescriptionCard
        icon={<Icon as={BsFillPersonLinesFill} boxSize={8} color="brand.300" />}
        title="Bespoke"
        width={["100%", "100%", "50%"]}
        color="brand.0"
      >
        Our carefully crafted and personalised program is tailored to each
        individual based on assessments and evaluations. We take a direct
        approach to improving learning abilities including perception,
        comprehension, and social-emotional skills.
      </DescriptionCard>
      <DescriptionCard
        icon={<Icon as={BsFillStarFill} boxSize={8} color="brand.300" />}
        title="Holistic"
        width={["100%", "100%", "50%"]}
        color="brand.0"
      >
        We view our clients as a whole and take an integrated approach to
        developing our client’s abilities. This means working with our students,
        their teachers, and also their families to bring out the best in them.
      </DescriptionCard>
      <DescriptionCard
        icon={<Icon as={BsFillSuitHeartFill} boxSize={8} color="brand.300" />}
        title="Proven"
        width={["100%", "100%", "50%"]}
        color="brand.0"
      >
        Our program has been proven to be effective with over a hundred
        satisfied clients in our alumni. Our tailored approach to developing
        learning skills has been successful in helping clients succeed.
      </DescriptionCard>
    </Stack>
  );
}
