import React, { useState } from "react";
import { useDisclosure, useToast } from "@chakra-ui/react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  InputLeftAddon,
  Stack,
  HStack,
  Spacer,
  Checkbox,
  Icon,
} from "@chakra-ui/react";
import { EmailIcon } from "@chakra-ui/icons";
import { BsFillPersonFill } from "react-icons/bs";

import { sendEmail } from "../firebase/functions";

export default function ContactForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [hasError, setHasError] = useState(false);
  const { isOpen, onToggle } = useDisclosure();

  const toast = useToast();

  const handleSubmit = (e: React.FormEvent) => {
    console.log("form submit");
    e.preventDefault();
    if (name === "" || email === "") {
      setHasError(true);
      toast({
        title: "Error: Missing information.",
        description: "Please include a name and email.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    setHasError(false);
    sendEmail({ email, name, message }).then((res) => {
      console.log(res);
      toast({
        title: "Message Sent!",
        description:
          "We will get back to you soon. Do check your other email folders.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    });
  };
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const handleMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value);
  };

  return (
    <Stack px={[8, 4, 4]}>
      <form onSubmit={handleSubmit}>
        <Stack spacing={4}>
          <FormControl isInvalid={hasError}>
            <InputGroup variant="custom">
              <InputLeftAddon>Name: </InputLeftAddon>
              <Input
                type="text"
                value={name}
                onChange={handleNameChange}
                placeholder="How we can address you"
              />
              <InputRightElement>
                <Icon as={BsFillPersonFill} />
              </InputRightElement>
            </InputGroup>
            {hasError && <FormErrorMessage>Name is required.</FormErrorMessage>}
          </FormControl>
          <FormControl isInvalid={hasError}>
            <InputGroup variant="custom">
              <InputLeftAddon>Email: </InputLeftAddon>
              <Input
                type="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="name@domain.com"
              />
              <InputRightElement>
                <EmailIcon />
              </InputRightElement>
            </InputGroup>
            {email !== "" && (
              <FormHelperText>
                By submitting, you agree to our privacy policy. <br />
                We won't share your email. And you won't be getting newsletters.
              </FormHelperText>
            )}
            {hasError && (
              <FormErrorMessage>Email is required.</FormErrorMessage>
            )}
          </FormControl>
          {isOpen && (
            <FormControl>
              <FormLabel>Message</FormLabel>
              <Input
                type="text"
                value={message}
                onChange={handleMessageChange}
                placeholder="[Optional] I want to know..."
              />
            </FormControl>
          )}
          <HStack>
            <Checkbox onChange={onToggle}>Add optional message</Checkbox>
            <Spacer />
            <Button bg="brand.300" color="brand.0" type="submit">
              Submit
            </Button>
          </HStack>
        </Stack>
      </form>
    </Stack>
  );
}
