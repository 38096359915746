import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";

interface TextAccordionProps {
  title: string;
  children?: React.ReactNode;
}

export default function TextAccordion({ title, children }: TextAccordionProps) {
  return (
    <AccordionItem>
      <AccordionButton _expanded={{ bg: "brand.100", color: "white" }}>
        <Box as="span" flex="1" textAlign="left" fontSize="lg">
          {title}
        </Box>
        <AccordionIcon />
      </AccordionButton>

      <AccordionPanel pb={4}>{children}</AccordionPanel>
    </AccordionItem>
  );
}
